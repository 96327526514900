import React, { FunctionComponent } from 'react';
import I18n from 'common/i18n';
import optionallyLocalizeUrls from '../../utils/optionally_localize_urls';

import { ForgeButton, ForgeCard, ForgeDialog, ForgeLabelValue, ForgeScaffold } from '@tylertech/forge-react';
import {
  canCreateStories,
  canCreateMeasures,
  canCreateDatasets,
  canCreateESRIMapLayers
} from '../../utils/site_chrome_rights';
import FeatureFlags from 'common/feature_flags';
import './index.scss';
import getLocalePrefix from 'common/js_utils/getLocalePrefix';

interface CreateAssetsDialogProps {
  open: boolean;
  closeDialogCallback: () => void;
}

const CreateAssetsDialog: FunctionComponent<CreateAssetsDialogProps> = ({ open, closeDialogCallback }) => {
  const scope = 'shared.site_chrome.forge_omnibar.create_assets';
  const options = {
    closeCallback: () => closeDialogCallback()
  };
  // Story label is replaced with Report label when the feature flag tyler_interactive_reporting_ui = true
  const label = FeatureFlags.value('tyler_interactive_reporting_ui') || FeatureFlags.value('flexible_layout_stories_only_tir_domains')
    ? I18n.t(`${scope}.report.label`)
    : I18n.t(`${scope}.story.label`);

  const linkDictionary = {
    dataset: optionallyLocalizeUrls('/datasets/new'),
    story: `/stories${getLocalePrefix()}/new`,
    measure: optionallyLocalizeUrls('/measures/new'),
    esri: '/datasets/new?onlyEsri=true'
  };

  const clickHandler = (url: string) => window.location.href = url;

  return (
    <ForgeDialog open={open} options={options}>
      <div className="create-assets-dialog">
        <h1 className="forge-typography--headline4">{I18n.t(`${scope}.heading`)}</h1>
        <div className="create-assets-cards">
          {canCreateDatasets() && (
            <ForgeCard>
              <ForgeScaffold>
                <div slot="body" className="create-dataset-card-body">
                  <img src="https://cdn.forge.tylertech.com/v1/images/spot/data-table-spot.svg" alt="" />
                  <ForgeLabelValue align="center">
                    <div slot="label" className="forge-typography--subtitle1-secondary">
                      {I18n.t(`${scope}.dataset.label`)}
                    </div>
                    <div slot="value" className="forge-typography--caption">
                      {I18n.t(`${scope}.dataset.description`)}
                    </div>
                  </ForgeLabelValue>
                </div>
                <div className="select-button" slot="footer">
                  <ForgeButton
                    id="forge-create-dataset-button"
                    type="raised"
                    onClick={() => clickHandler(linkDictionary.dataset)}
                  >
                    <button forge-dialog-focus="true">{I18n.t(`${scope}.select`)}</button>
                  </ForgeButton>
                </div>
              </ForgeScaffold>
            </ForgeCard>
          )}
          {canCreateStories() && (
            <ForgeCard>
              <ForgeScaffold>
                <div slot="body" className="create-story-card-body">
                  <img src="https://cdn.forge.tylertech.com/v1/images/spot/designer-spot.svg" alt="" />
                  <ForgeLabelValue align="center">
                    <div slot="label" className="forge-typography--subtitle1-secondary">
                      {label}
                    </div>
                    <div slot="value" className="forge-typography--caption">
                      {I18n.t(`${scope}.story.description`)}
                    </div>
                  </ForgeLabelValue>
                </div>
                <div className="select-button" slot="footer">
                  <ForgeButton
                    id="forge-create-story-button"
                    type="raised"
                    onClick={() => clickHandler(linkDictionary.story)}
                  >
                    <button>{I18n.t(`${scope}.select`)}</button>
                  </ForgeButton>
                </div>
              </ForgeScaffold>
            </ForgeCard>
          )}
          {canCreateMeasures() && (
            <ForgeCard>
              <ForgeScaffold>
                <div slot="body" className="create-measure-card-body">
                  <img src="https://cdn.forge.tylertech.com/v1/images/spot/chart-spot.svg" alt="" />
                  <ForgeLabelValue align="center">
                    <div slot="label" className="forge-typography--subtitle1-secondary">
                      {I18n.t(`${scope}.measure.label`)}
                    </div>
                    <div slot="value" className="forge-typography--caption">
                      {I18n.t(`${scope}.measure.description`)}
                    </div>
                  </ForgeLabelValue>
                </div>
                <div className="select-button" slot="footer">
                  <ForgeButton
                    id="forge-create-measure-button"
                    type="raised"
                    onClick={() => clickHandler(linkDictionary.measure)}
                  >
                    <button>{I18n.t(`${scope}.select`)}</button>
                  </ForgeButton>
                </div>
              </ForgeScaffold>
            </ForgeCard>
          )}
          {canCreateESRIMapLayers() && (
            <ForgeCard>
              <ForgeScaffold>
                <div slot="body" className="create-esri-layer-card-body">
                  <img src="https://cdn.forge.tylertech.com/v1/images/spot/map-2-spot.svg" alt="" />
                  <ForgeLabelValue align="center">
                    <div slot="label" className="forge-typography--subtitle1-secondary">
                      {I18n.t(`${scope}.esri.label`)}
                    </div>
                    <div slot="value" className="forge-typography--caption">
                      {I18n.t(`${scope}.esri.description`)}
                    </div>
                  </ForgeLabelValue>
                </div>
                <div className="select-button" slot="footer">
                  <ForgeButton
                    id="forge-create-esri-button"
                    type="raised"
                    onClick={() => clickHandler(linkDictionary.esri)}
                  >
                    <button>{I18n.t(`${scope}.select`)}</button>
                  </ForgeButton>
                </div>
              </ForgeScaffold>
            </ForgeCard>
          )}
        </div>
        <div className="close-button">
          <ForgeButton type="outlined">
            <button onClick={closeDialogCallback}>{I18n.t(`${scope}.cancel`)}</button>
          </ForgeButton>
        </div>
      </div>
    </ForgeDialog>
  );
};

export default CreateAssetsDialog;
