import { TableAliases, emptyTableAliases } from 'common/types/compiler';
import {
  BinaryTree, CompoundOp, isCompound,
  AnalyzedAst, AnalyzedSelectedExpression, AnalyzedJoin,
  isJoinByFromTable, isJoinByJoinFunc, isJoinBySubSelect, isJoinBySubAnalysis,
  TypedSelect, Selection as AstSelection,
  UnAnalyzedAst, UnAnalyzedJoin
} from 'common/types/soql';

export const rightmostLeaf = <T>(tree: BinaryTree<T>): T => {
  if (tree.type === 'leaf') {
    return tree.value;
  } else {
    return rightmostLeaf(tree.right);
  }
};

const reduceTree = <S, T>(tree: BinaryTree<S>, accumulator: T, reducer: (accum: T, tree: S) => T): T => {
  if (tree.type === 'leaf') {
    return reducer(accumulator, tree.value);
  } else {
    return reduceTree(tree.right, reduceTree(tree.left, accumulator, reducer), reducer);
  }
};

export const extractSelectionForColumnNames = (tree: BinaryTree<AnalyzedAst>): AnalyzedSelectedExpression[] => {
  if (isCompound(tree)) {
    if (tree.op === CompoundOp.QueryPipe) {
      return extractSelectionForColumnNames(tree.right);
    } else {
      return extractSelectionForColumnNames(tree.left);
    }
  }

  return tree.value.selection;
};
export const extractSelectionForColumnNamesNA = (tree: BinaryTree<TypedSelect>): AstSelection => {
  if (isCompound(tree)) {
    if (tree.op === CompoundOp.QueryPipe) {
      return extractSelectionForColumnNamesNA(tree.right);
    } else {
      return extractSelectionForColumnNamesNA(tree.left);
    }
  }

  return tree.value.selection;
};

export const extractTableAliasesFromAst = (treeAst: BinaryTree<AnalyzedAst> | BinaryTree<UnAnalyzedAst>): TableAliases => {
  return reduceTree<AnalyzedAst | UnAnalyzedAst, TableAliases>(
    treeAst,
    emptyTableAliases(),
    (accum, ast: AnalyzedAst | UnAnalyzedAst) => {
      if (ast.from?.alias) {
        if (ast.from.name === '_this') {
          accum = { ...accum, realTables: { ...accum.realTables, [ast.from.alias]: '_' }};
        } else {
          accum = { ...accum, realTables: { ...accum.realTables, [ast.from.alias]: ast.from.name }};
        }
      }
      ast.joins.forEach((join: AnalyzedJoin | UnAnalyzedJoin)  => {
        if (isJoinByFromTable(join.from)) {
          if (join.from.from_table.alias) {
            accum = { ...accum, realTables: {
              ...accum.realTables,
              [join.from.from_table.alias]: join.from.from_table.name
            }};
          }
        } else if (isJoinByJoinFunc(join.from)) {
          if (join.from.join_function.table_name.alias) {
            accum = { ...accum, virtualTables: [
              ...accum.virtualTables,
              join.from.join_function.table_name.alias
            ]};
          }
        } else if (isJoinBySubSelect(join.from)) {
          // We should only need to care about the aliases visible to the top-level query.
          if (join.from.sub_select.alias) {
            accum = { ...accum, virtualTables: [
              ...accum.virtualTables,
              join.from.sub_select.alias
            ]};
          }
        } else if (isJoinBySubAnalysis(join.from)) {
          // We should only need to care about the aliases visible to the top-level query.
          if (join.from.sub_analysis.alias) {
            accum = { ...accum, virtualTables: [
              ...accum.virtualTables,
              join.from.sub_analysis.alias
            ]};
          }
        } else {
          throw new Error(`unknown join type ${JSON.stringify(join.from)}`);
        }
      });
      return accum;
    });
};
