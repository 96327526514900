import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import I18n from 'common/i18n';

import { MODES } from 'common/components/AccessManager/Constants';

import { getUiMode } from 'common/components/AccessManager/sagas/Selectors';

import './header.scss';

const classNameScope = 'common--components--AccessManager--components--Header';

/**
 * Simple header for the top of the modal.
 *
 * The title and subtitle can be changed via the state, if so desired.
 */
const Header: FunctionComponent = () => {
  const mode = useSelector(getUiMode);
  const title = I18n.t(`shared.site_chrome.access_manager.${mode}.title`);
  const subtitle = I18n.t(`shared.site_chrome.access_manager.${mode}.subtitle`);

  return (
    <header className={`${classNameScope}--header`}>
      <h2 id="modal-header-title">{title}</h2>
      {subtitle}
    </header>
  );
};

export default Header;
