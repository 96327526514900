import { ForgeMenu, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';
import React, { useMemo, useState } from 'react';
import I18n from 'common/i18n';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { isVisualizationCanvas, isMeasure, isStory } from 'common/views/view_types';
import { View } from 'common/types/view';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';
import { AllowedSecondaryActions } from '../../lib/secondary_actions';
import { launchCopyAssetModal } from 'common/components/AssetActionBar/components/copy_asset_modal';
import { launchDeleteAssetModal } from 'common/components/AssetActionBar/components/delete_asset_modal';
import { explicitWithdrawApprovalModal } from 'common/components/AssetActionBar/components/confirmation_dialog/utils';
import { assign, reload } from 'common/window_location';
import { checkStatus } from 'common/http';
import { handlePostConfirmationError, requireApprovalRequestWithdrawal } from './utils';
import { localizeLink } from 'common/locale';
import { onLockAsset } from 'common/components/AssetBrowser/components/action_dropdown/action_items/lock_asset_action_item';
import { ToastType } from 'common/components/ToastNotification';
import optionallyLocalizeUrls from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';
import { MODES as ACCESS_MANAGER_MODES } from 'common/components/AccessManager/Constants';
import { withGuidanceV2 } from 'common/core/approvals/guidanceV2';
import { showToastOnPageReload } from 'common/components/ToastNotification/Toastmaster';
import { PublishActionProps } from './publication_action_props';
import ManageFederationsModal from 'common/components/ManageFederationsModal';
import WatchAssetManager from 'common/components/WatchAssetManager';
import { getCurrentUser } from 'common/current_user';

const scope = 'shared.components.asset_action_bar.publication_action';

/** List of actions that can show up in the asset action bar */
export enum PublicationActions {
  RevertToPublished = 'revert-to-published',
  RevertChildView = 'revert-child-view',
  ViewPublished = 'view-published',
  ChangeAudience = 'change-audience',
  ManageCollaborator = 'manage-collaborator',
  TransferOwnership = 'transfer-ownership',
  WithdrawApprovalRequest = 'withdraw-approval-request',
  ViewDraft = 'view-draft',
  Copy = 'copy',
  DeleteDataset = 'delete-dataset',
  LockAsset = 'lock-asset',
  WatchAsset = 'watch-asset',
  Federate = 'federate',
  EditMetadata = 'edit-metadata'
}

/** Option rendered in the dropdown */
export interface PublicationActionDropdownItem {
  title: string;
  value: PublicationActions;
  danger?: boolean;
}

function renderMoreActionsDropdownItems(
  approvalsGuidance: GuidanceSummaryV2,
  view: View,
  allowedActions: AllowedSecondaryActions
) {
  const actions: PublicationActionDropdownItem[] = [];

  if (allowedActions.editMetadata && (isVisualizationCanvas(view) || isMeasure(view))) {
    actions.push({
      title: I18n.t('edit_metadata', { scope }),
      value: PublicationActions.EditMetadata
    });
  }

  if (allowedActions.revert) {
    actions.push({
      title: I18n.t('revert_published', { scope }),
      value: PublicationActions.RevertToPublished
    });
  }

  if (allowedActions.revertChildView) {
    actions.push({
      title: I18n.t('revert_child_view', { scope }),
      value: PublicationActions.RevertChildView
    });
  }

  if (allowedActions.viewPublished) {
    actions.push({
      title: I18n.t('view_published', { scope }),
      value: PublicationActions.ViewPublished
    });
  }

  if (allowedActions.manageCollaborators) {
    actions.push({
      title: I18n.t('manage_access', { scope }),
      value: PublicationActions.ManageCollaborator
    });
  }

  if (allowedActions.changeAudience) {
    actions.push({
      title: I18n.t('change_audience', { scope }),
      value: PublicationActions.ChangeAudience
    });
  }

  if (allowedActions.federate) {
    actions.push({
      title: I18n.t('federate', { scope }),
      value: PublicationActions.Federate
    });
  }

  if (allowedActions.transferOwnership) {
    actions.push({
      title: I18n.t('transfer_ownership', { scope }),
      value: PublicationActions.TransferOwnership
    });
  }

  if (allowedActions.withdrawApprovalRequest) {
    actions.push({
      title: I18n.t('withdraw_approval_request', { scope }),
      value: PublicationActions.WithdrawApprovalRequest
    });
  }

  if (allowedActions.viewDraft) {
    actions.push({
      title: I18n.t('view_draft', { scope }),
      value: PublicationActions.ViewDraft
    });
  }

  // The "Watch" button allows a user to create and manage subscriptions to published Stories, powered by
  // notifications-and-alerts. Currently, this subscription emails the story as a static screenshot.
  if (allowedActions.watchView) {
    actions.push({
      title: I18n.t('watch', { scope }),
      value: PublicationActions.WatchAsset
    });
  }

  if (allowedActions.copy) {
    actions.push({
      title: I18n.t('copy_asset', { scope }),
      value: PublicationActions.Copy
    });
  }

  if (allowedActions.lockAsset) {
    actions.push({
      title: view.locked ? I18n.t('unlock_asset', { scope }) : I18n.t('lock_asset', { scope }),
      value: PublicationActions.LockAsset
    });
  }

  if (allowedActions.deleteDataset) {
    const assetStatus = assetStatusFor(view, approvalsGuidance);

    actions.push({
      title:
        assetStatus === AssetStatus.Draft
          ? I18n.t('discard_draft', { scope })
          : I18n.t('delete_this_asset', { scope }),
      value: PublicationActions.DeleteDataset,
      danger: true
    });
  }

  return actions;
}

export type PublicationMoreActionButtonProps = Pick<
  PublishActionProps,
  'approvalsGuidance' | 'view' | 'allowedActions' | 'publishedViewUid' | 'onCopy' | 'copyBasedOn'
> & {
  updateAccessManagerMode: (r: ACCESS_MANAGER_MODES) => void;
};

export function PublicationMoreActionButton({
  approvalsGuidance,
  view,
  allowedActions,
  publishedViewUid,
  onCopy,
  copyBasedOn,
  updateAccessManagerMode
}: PublicationMoreActionButtonProps) {
  const jsxToHtmlElementService = useMemo(() => new JsxToHtmlElementService(), []);

  const [federateModalVisible, setFederateModalVisible] = useState(false);
  const [watchListVisible, setWatchListVisible] = useState(
    new URLSearchParams(window.location.search).getAll('modal').includes('WatchAssetManager')
  );

  const listItems = useMemo(
    () => renderMoreActionsDropdownItems(approvalsGuidance, view, allowedActions),
    [allowedActions, approvalsGuidance, view]
  );

  if (listItems.length === 0) {
    return null;
  }

  const label = I18n.t('shared.components.asset_action_bar.publication_action.more_actions');
  const optionBuilder = (item: PublicationActionDropdownItem) =>
    jsxToHtmlElementService.wrapJsx(
      <PublicationMoreOption item={item} />,
      `more_actions_list_item_${item.value}`
    );

  const toastLater = (toastType: ToastType, translationKey: string, translationOptions: any) => {
    showToastOnPageReload({
      type: toastType,
      content: I18n.t(translationKey, { ...translationOptions, scope })
    });
  };

  const handleMoreActions = (option: PublicationActionDropdownItem) => {
    const { value } = option;

    const publishedViewLink = () => {
      if (publishedViewUid) {
        return isStory(view)
          ? localizeLink(`/stories/s/${publishedViewUid}`)
          : localizeLink(`/d/${publishedViewUid}`);
      }

      /**
       * CAUTION: If `publishedViewUid` is an empty string, we'll actually return undefined here.
       * This is then passed in `assign`, which calls `window.location.assign`. Calling that
       * with undefined takes us to an error page. It's unclear when the `publishedViewUid` is
       * ever an empty string, so this case might not ever happen, but Just leaving a message
       * here that it could.
       */
    };

    const redirectAfterDeletion = () => {
      return publishedViewLink() || optionallyLocalizeUrls('/profile');
    };

    switch (value) {
      case PublicationActions.Copy: {
        // eslint-disable-next-line no-undef
        if (view.viewType === 'story' && window.storyteller && window.storyteller.reduxStore) {
          // Reach into Storyteller's brain. Gross.
          window.storyteller.reduxStore.dispatch({ type: 'storyCopierSlice/openCopyStoryModal' });
        } else {
          launchCopyAssetModal(view.name, view.assetType, onCopy, copyBasedOn);
        }
        break;
      }
      case PublicationActions.RevertToPublished:
        // TODO someday. Currently postponed.
        console.warn('revert-to-published option not implemented');
        break;
      case PublicationActions.RevertChildView:
        // "revert" here meaning just reload the page which will re-fetch the view
        reload();
        break;
      case PublicationActions.ViewPublished:
        assign(publishedViewLink());
        break;
      case PublicationActions.ViewDraft:
        // only for stories currently
        assign(`/stories/s/${view.id}/preview`);
        break;
      case PublicationActions.ChangeAudience:
        const openAccessManager = () => updateAccessManagerMode(ACCESS_MANAGER_MODES.CHANGE_AUDIENCE);
        requireApprovalRequestWithdrawal(approvalsGuidance).then((confirmed: boolean) => {
          if (confirmed) {
            openAccessManager();
          }
        });
        break;
      case PublicationActions.TransferOwnership:
        updateAccessManagerMode(ACCESS_MANAGER_MODES.CHANGE_OWNER);
        break;
      case PublicationActions.ManageCollaborator:
        updateAccessManagerMode(ACCESS_MANAGER_MODES.MANAGE_COLLABORATORS);
        break;
      case PublicationActions.DeleteDataset:
        // if revisionSeq exists, this will delete the revision
        const revisionSeq = window.initialState?.revisionSeq;
        launchDeleteAssetModal(view, redirectAfterDeletion, revisionSeq);
        break;
      case PublicationActions.LockAsset:
        onLockAsset(view);
        break;
      case PublicationActions.WatchAsset:
        setWatchListVisible(true);
        break;
      case PublicationActions.WithdrawApprovalRequest:
        explicitWithdrawApprovalModal().then((confirmResolution) => {
          if (confirmResolution.confirmed) {
            withGuidanceV2(approvalsGuidance)
              .withdraw()
              .then(checkStatus)
              .then(() => {
                toastLater(ToastType.SUCCESS, 'withdraw_approval_request_success', { name: view.name });
                reload();
              })
              .catch(() => {
                handlePostConfirmationError();
              });
          }
        });
        break;
      case PublicationActions.Federate:
        setFederateModalVisible(true);
        break;
      case PublicationActions.EditMetadata:
        assign(`/d/${view.id}/edit_metadata`);
        break;
    }
  };

  const currentUser = getCurrentUser();

  const closeWatchAssetModal = () => {
    setWatchListVisible(false);
    window.history.pushState({}, document.title, window.location.pathname);
  };

  return (
    <>
      <ForgeMenu
        options={listItems}
        optionBuilder={optionBuilder}
        on-forge-menu-select={({ detail }: CustomEvent<PublicationActionDropdownItem>) =>
          handleMoreActions(detail)
        }
      >
        <ForgeIconButton className="forge-popup-host">
          <button type="button" aria-label={label} className="more-actions-button">
            <ForgeIcon name="more_vert" />
          </button>
        </ForgeIconButton>
      </ForgeMenu>

      {federateModalVisible && (
        <ManageFederationsModal view={view} onDismiss={() => setFederateModalVisible(false)} />
      )}

      {watchListVisible && currentUser && (
        <WatchAssetManager onDismiss={closeWatchAssetModal} view={view} currentUser={currentUser} />
      )}
    </>
  );
}

export function PublicationMoreOption({ item }: { item: PublicationActionDropdownItem }) {
  const testId = `publication-action-${item.value}-button`;

  return (
    <span data-testid={testId} className={item.danger ? 'more-action-danger' : ''}>
      {item.title}
    </span>
  );
}
