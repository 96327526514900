import React, { FunctionComponent } from 'react';
import I18n from 'common/i18n';
import { ForgeCard, ForgeButton, ForgeIcon, ForgeIconButton, ForgeLabelValue } from '@tylertech/forge-react';
import optionallyLocalizeUrls from '../../utils/optionally_localize_urls';
import {
  canCreateStories,
  canCreateMeasures,
  canCreateDatasets,
  canCreateESRIMapLayers
} from '../../utils/site_chrome_rights';
import FeatureFlags from 'common/feature_flags';
import './index.scss';

interface MobileCreateAssetsDialogProps {
  open: boolean;
  closeDrawerCallback: () => void;
}

const MobileCreateAssetsDialog: FunctionComponent<MobileCreateAssetsDialogProps> = ({
  open,
  closeDrawerCallback
}) => {
  const scope = 'shared.site_chrome.forge_omnibar.create_assets';
  // Story label is replaced with Report label when the feature flag tyler_interactive_reporting_ui = true
  const label = FeatureFlags.value('tyler_interactive_reporting_ui')
    ? I18n.t(`${scope}.report.label`)
    : I18n.t(`${scope}.story.label`);

  return open ? (
    <div className="mobile-create-assets-drawer">
      <div className="mobile-create-assets-drawer-header">
        <ForgeIconButton id="close-more-action-page">
          <button
            type="button"
            aria-label="View user profile"
            className="tyler-icons"
            onClick={() => closeDrawerCallback()}
          >
            <ForgeIcon name="close" />
          </button>
        </ForgeIconButton>
      </div>
      <div className="create-assets-dialog">
        <h1 className="forge-typography--headline5">{I18n.t(`${scope}.heading`)}</h1>
        <div className="create-assets-cards">
          {canCreateDatasets() && (
            <ForgeCard>
              <img src="https://cdn.forge.tylertech.com/v1/images/spot/data-table-spot.svg" alt="" />
              <ForgeLabelValue align="center">
                <div slot="label">{I18n.t(`${scope}.dataset.label`)}</div>
                <div slot="value" className="forge-typography--caption">
                  {I18n.t(`${scope}.dataset.description`)}
                </div>
              </ForgeLabelValue>
              <div className="select-button">
                <ForgeButton type="raised" id="forge-create-dataset-button">
                  <button onClick={() => (location.href = optionallyLocalizeUrls('/datasets/new'))}>
                    {I18n.t(`${scope}.select`)}
                  </button>
                </ForgeButton>
              </div>
            </ForgeCard>
          )}
          {canCreateStories() && (
            <ForgeCard>
              <img src="https://cdn.forge.tylertech.com/v1/images/spot/designer-spot.svg" alt="" />
              <ForgeLabelValue align="center">
                <div slot="label">{label}</div>
                <div slot="value" className="forge-typography--caption">
                  {I18n.t(`${scope}.story.description`)}
                </div>
              </ForgeLabelValue>
              <div className="select-button">
                <ForgeButton type="raised" id="forge-create-story-button">
                  <button onClick={() => (location.href = optionallyLocalizeUrls('/stories/new'))}>
                    {I18n.t(`${scope}.select`)}
                  </button>
                </ForgeButton>
              </div>
            </ForgeCard>
          )}
          {canCreateMeasures() && (
            <ForgeCard>
              <img src="https://cdn.forge.tylertech.com/v1/images/spot/chart-spot.svg" alt="" />
              <ForgeLabelValue align="center">
                <div slot="label">{I18n.t(`${scope}.measure.label`)}</div>
                <div slot="value" className="forge-typography--caption">
                  {I18n.t(`${scope}.measure.description`)}
                </div>
              </ForgeLabelValue>
              <div className="select-button">
                <ForgeButton type="raised" id="forge-create-measure-button">
                  <button onClick={() => (location.href = optionallyLocalizeUrls('/measures/new'))}>
                    {I18n.t(`${scope}.select`)}
                  </button>
                </ForgeButton>
              </div>
            </ForgeCard>
          )}
          {canCreateESRIMapLayers() && (
            <ForgeCard>
              <img src="https://cdn.forge.tylertech.com/v1/images/spot/map-2-spot.svg" alt="" />
              <ForgeLabelValue align="center">
                <div slot="label">{I18n.t(`${scope}.esri.label`)}</div>
                <div slot="value" className="forge-typography--caption">
                  {I18n.t(`${scope}.esri.description`)}
                </div>
              </ForgeLabelValue>
              <div className="select-button">
                <ForgeButton type="raised">
                  <button
                    onClick={() => (location.href = optionallyLocalizeUrls('/datasets/new?onlyEsri=true'))}
                  >
                    {I18n.t(`${scope}.select`)}
                  </button>
                </ForgeButton>
              </div>
            </ForgeCard>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default MobileCreateAssetsDialog;
