import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import I18n from 'common/i18n';

import * as constants from 'common/components/AssetBrowser/lib/constants';
import { getIncomingFederations } from 'common/federation/utils';
import * as filtersActions from 'common/components/AssetBrowser/actions/filters';

const scope = 'shared.asset_browser.header.asset_counts';

export class AssetCounts extends Component {
  UNSAFE_componentWillReceiveProps() {
    this.render();
  }

  renderFederationsWarning() {
    return (
      <div className='asset-counts-federation-warning'>
        {I18n.t('federation_warning', { scope })}
      </div>
    );
  }

  render() {
    const { activeTab, assetCounts, fetchingAssetCounts, fetchingAssetCountsError, filters } = this.props;

    if (activeTab === constants.TAB_FEDERATED) {
      return null;
    }

    const assetTypeTranslation = (key, count) => I18n.t(key, { count, scope });

    const sortedAssetCounts = _(assetCounts).toPairs().sortBy(0).fromPairs().value();

    const assetCountItems = _.map(sortedAssetCounts, (assetCount, assetType) => {
      if (assetCount === 0) return;
      let assetTypeName;

      if (isEmpty(assetTypeName) && assetType === 'datasets,system_datasets') {
        assetTypeName = assetTypeTranslation('datasets', assetCount);
      }

      if (isEmpty(assetTypeName)) {
        assetTypeName = assetTypeTranslation(assetType, assetCount);
      }

      let assetCountLink = null;
      if (filters.assetTypes === assetType) {
        assetCountLink = assetCount;
      } else {
        assetCountLink = (
          <a onClick={() => this.props.changeAssetType(assetType)}>
            {assetCount}
          </a>
        );
      }

      const assetCountsItemClass = classNames('asset-counts-item', {[assetType]: assetType});

      return (
        <div className={assetCountsItemClass} key={assetType}>
          <div className="item-count">{assetCountLink}</div>
          <div className="item-name">{assetTypeName}</div>
        </div>
      );
    });

    const assetCountsClass = classNames('asset-counts', {
      'fetching': fetchingAssetCounts,
      'has-error': fetchingAssetCountsError
    });

    const incomingFederationsPresent = !isEmpty(getIncomingFederations());
    const showFederationsWarning = activeTab === constants.TAB_ALL_ASSETS && incomingFederationsPresent;

    return (
      <div className={assetCountsClass}>
        {showFederationsWarning && this.renderFederationsWarning() }
        {assetCountItems}
      </div>
    );
  }
}

AssetCounts.propTypes = {
  assetCounts: PropTypes.shape({
    charts: PropTypes.number,
    'datasets,system_datasets': PropTypes.number,
    files: PropTypes.number,
    filters: PropTypes.number,
    hrefs: PropTypes.number,
    maps: PropTypes.number,
    measures: PropTypes.number,
    stories: PropTypes.number
  }).isRequired,
  changeAssetType: PropTypes.func,
  fetchingAssetCounts: PropTypes.bool,
  fetchingAssetCountsError: PropTypes.bool,
  filters: PropTypes.shape({
    assetTypes: PropTypes.string
  }),
  activeTab: PropTypes.string
};

const mapStateToProps = (state) => ({
  assetCounts: _.get(state, 'assetCounts.values', {}),
  fetchingAssetCounts: _.get(state, 'assetCounts.fetchingAssetCounts', false),
  fetchingAssetCountsError: _.get(state, 'assetCounts.fetchingAssetCountsError', false),
  filters: state.filters,
  activeTab: _.get(state, 'header.activeTab')
});

const mapDispatchToProps = (dispatch) => ({
  changeAssetType: (value) => dispatch(filtersActions.changeAssetType(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetCounts);
