import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Dropdown from 'common/components/Dropdown';
import AssetTypeIcon from 'common/components/AssetTypeIcon';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';

import * as filters from 'common/components/AssetBrowser/actions/filters';

export class AssetTypesFilter extends Component {
  getAssetTypeOptions() {
    const scope = 'shared.asset_browser.filters.asset_types';

    const assetTypeOptions = [
      {
        title: I18n.t('options.all', { scope }),
        value: null,
        defaultOption: true,
        assetTypes: null
      },
      {
        title: I18n.t('options.calendars', { scope }),
        value: 'calendars',
        assetTypes: 'calendars',
        icon: <AssetTypeIcon displayType="calendar" />
      },
      {
        title: I18n.t('options.charts', { scope }),
        value: 'charts',
        assetTypes: 'charts',
        icon: <AssetTypeIcon displayType="chart" />
      },
      {
        title: I18n.t('options.datasets', { scope }),
        value: 'datasets',
        assetTypes: 'datasets,system_datasets',
        icon: <AssetTypeIcon displayType="dataset" />
      },
      {
        title: I18n.t('options.external', { scope }),
        value: 'external_datasets',
        assetTypes: 'hrefs,federated_hrefs',
        icon: <AssetTypeIcon displayType="href" />
      },
      {
        title: I18n.t('options.files', { scope }),
        value: 'files',
        assetTypes: 'files',
        icon: <AssetTypeIcon displayType="attachment" />
      },
      {
        title: I18n.t('options.filtered', { scope }),
        value: 'filters',
        assetTypes: 'filters',
        icon: <AssetTypeIcon displayType="filter" />
      },
      {
        title: I18n.t('options.forms', { scope }),
        value: 'forms',
        assetTypes: 'forms',
        icon: <AssetTypeIcon displayType="form" />
      },
      {
        title: I18n.t('options.maps', { scope }),
        value: 'maps',
        assetTypes: 'maps',
        icon: <AssetTypeIcon displayType="map" />
      },
      {
        title: I18n.t('options.system_datasets', { scope }),
        value: 'system_datasets',
        assetTypes: 'system_datasets',
        icon: <AssetTypeIcon displayType="system_dataset" />
      }];

    if (FeatureFlags.value('open_performance_standalone_measures')) {
      assetTypeOptions.push({
        title: I18n.t('options.measures', { scope }),
        value: 'measures',
        assetTypes: 'measures',
        icon: <AssetTypeIcon displayType="measure" />
      });
    }

    if (FeatureFlags.value('stories_enabled')) {
      assetTypeOptions.push({
        title: I18n.t('options.stories', { scope }),
        value: 'stories',
        assetTypes: 'stories',
        icon: <AssetTypeIcon displayType="story" />
      });
    }

    return assetTypeOptions.map((assetTypeOption) => {
      assetTypeOption.catalogParams = assetTypeOption.catalogParams || { 'collectionParent': null };
      return assetTypeOption;
    });
  }

  getOptionValue(options, assetTypes) {
    const option = _.isEmpty(options) || _.isEmpty(assetTypes) ?
      null : _.find(options, x => x.assetTypes === assetTypes);
    return option && option.value;
  }

  render() {
    const { assetTypes, changeAssetType } = this.props;
    const scope = 'shared.asset_browser.filters.asset_types';
    const options = this.getAssetTypeOptions();

    return (
      <div className="filter-section asset-types">
        <label className="filter-label">{I18n.t('label', { scope })}</label>
        <Dropdown
          name="asset_types"
          onSelection={(option) => {
            if (assetTypes !== option.assetTypes) {
              changeAssetType(option.assetTypes, option.catalogParams);
            }
          }}
          options={options}
          size="medium"
          value={this.getOptionValue(options, assetTypes) || null} />
      </div>
    );
  }
}

AssetTypesFilter.propTypes = {
  assetTypes: PropTypes.string,
  changeAssetType: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  assetTypes: state.filters.assetTypes,
});

const mapDispatchToProps = (dispatch) => ({
  changeAssetType: (assetTypes, catalogParams = {}) => dispatch(filters.changeAssetType(assetTypes, catalogParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetTypesFilter);
