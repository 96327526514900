import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useQuery } from 'react-query';

import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import { defaultHeaders } from 'common/http';
import { hasGrantRight } from 'common/views/has_rights';
import { hasBeenPublished } from 'common/views/publicationHelpers';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { AudienceScope, View, ViewPermissions } from 'common/types/view';
import { mapPermissionScopeToTargetAudience, withGuidanceV2 } from 'common/core/approvals/index_new';
import { isMeasure, isStory, isVisualizationCanvas } from 'common/views/view_types';
import {
  showToastNow,
  showToastOnPageReload,
  ToastType
} from 'common/components/ToastNotification/Toastmaster';
import AccessManager from 'common/components/AccessManager';
import { MODES as ACCESS_MANAGER_MODES } from 'common/components/AccessManager/Constants';
import {
  publishUrl,
  savePermissions,
  shouldPublishOnSave,
  shouldUpdateViewOnPublish
} from 'common/components/AccessManager/Util';
import SubmitForApprovalButton from 'common/components/AssetActionBar/components/submit_for_approval_button';
import { PrimaryAction } from 'common/components/AssetActionBar/lib/primary_action';
import StoryEditButton from 'common/components/AssetActionBar/components/edit_button/story_edit_button';
import EditButton from 'common/components/AssetActionBar/components/edit_button/edit_button';
import { PublishButton } from './publish_button';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';
import optionallyLocalizeUrls from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';
import { checkIsMetadataValid } from 'common/views/helpers';
import { FixMetadataMessage } from '../fix_metadata_message';
import { PublicationMoreActionButton } from './publication_more_action';
import { requireApprovalRequestWithdrawal } from './utils';
import { PublishActionProps } from './publication_action_props';

const fetchOptions: RequestInit = {
  credentials: 'same-origin',
  headers: defaultHeaders
};

export const requireApprovalRequestWithdrawalBeforeAction = (
  approvalsGuidance: GuidanceSummaryV2,
  action: () => void
) => {
  requireApprovalRequestWithdrawal(approvalsGuidance).then((confirmed: boolean) => {
    if (confirmed) {
      action();
    }
  });
};

async function pollForView(assetUid: string, ticket: string): Promise<View> {
  const resp = await fetch(`/api/views/${assetUid}/publication.json?ticket=${ticket}`, {
    headers: defaultHeaders
  });
  const status = resp.status;
  if (status === 202) {
    await sleep(1000);
    return await pollForView(assetUid, ticket);
  } else {
    return (await resp.json()) as View;
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export type RenderPrimaryButtonFunction = (options: {
  approvalsGuidance: GuidanceSummaryV2;
  view: View;
  previouslyPublished: boolean;
  onClick: () => void;
  publishing?: boolean;
}) => ReactNode;

export function PublicationAction(props: PublishActionProps) {
  const jsxToHtmlElementService = useMemo(() => new JsxToHtmlElementService(), []);

  const [accessManagerMode, setAccessManagerMode] = useState<ACCESS_MANAGER_MODES | null>(null);
  const [publishing, setPublishing] = useState(false);

  const actionTypeCaresAboutMetadataValidity = (primaryAction: PrimaryAction) =>
    [PrimaryAction.PUBLISH_OR_EDIT_DRAFT, PrimaryAction.SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT].includes(
      primaryAction
    );

  const assetTypeCaresAboutMetadataValidity = (view: View) => isMeasure(view) || isVisualizationCanvas(view);

  const { status: validityStatus, data: metadataIsValid } = useQuery(
    `check-metadata-validity-${props.view.id}`,
    async () => {
      if (
        !actionTypeCaresAboutMetadataValidity(props.primaryAction) ||
        !assetTypeCaresAboutMetadataValidity(props.view)
      )
        return Promise.resolve(true);
      return await checkIsMetadataValid(props.view.id);
    },
    {
      // keep the value cached for 30 seconds
      staleTime: 30 * 1000,
      refetchOnWindowFocus: false
    }
  );

  // prevents memory leaks due to lingering elements after component unmounts
  useEffect(() => {
    return () => {
      jsxToHtmlElementService.deleteAll();
    };
  }, [jsxToHtmlElementService]);

  const saveViewChanges = () => {
    if (props.customSaveFunc) return props.customSaveFunc();
    const gridViewDataset = get(window, 'blist.dataset');
    if (!gridViewDataset) {
      throw new Error('Tried to save child view changes but blist.dataset is not available');
    }

    // saveView already has onSuccess and onFailure as parameters
    return new Promise(gridViewDataset.saveView.bind(gridViewDataset));
  };

  const openAccessManagerForPublish = () => {
    setAccessManagerMode(ACCESS_MANAGER_MODES.PUBLISH);
  };

  const closeAccessManager = () => {
    setAccessManagerMode(null);
  };

  // when the user clicks "publish", present a dialog for audience selection or just publish
  const publishButtonOnClick = async () => {
    const { approvalsGuidance, view } = props;

    if (!(await requireApprovalRequestWithdrawal(approvalsGuidance))) {
      return;
    }

    const skipAudienceSelection =
      hasBeenPublished(view) || // has been previously published doesn't change audience
      !hasGrantRight(view);

    if (skipAudienceSelection) {
      setPublishing(true);

      // This basically simulates the params returned by the modal, which is a bit awkward
      try {
        // Don't change the permissions from whatever they currently are
        await saveChanges(ACCESS_MANAGER_MODES.PUBLISH);
      } catch (error) {
        await sleep(1000);
        setPublishing(false);
        showToastNow({
          type: ToastType.ERROR,
          content: I18n.t('shared.components.asset_action_bar.publication_action.unknown_error')
        });
      }
    } else {
      openAccessManagerForPublish();
    }
  };

  /**
   * Called when the user has made changes we want to persist
   * Will handle saving the permissions and publishing the view
   */
  const saveChanges = async (
    mode: ACCESS_MANAGER_MODES,
    assetUid?: string,
    accessManagerPermissions?: ViewPermissions
  ) => {
    const { view, approvalsGuidance } = props;
    // We invoke different modes of the access manager, and not all of them involve publishing
    if (shouldPublishOnSave(mode)) {
      // TODO: We are slowly converging on The One Function To Publish Everything.
      // When this finally happens, please factor this out. The main holdout is DSMP (aka DSMUI),
      // which passes in a completely custom onPublish callback.

      if (typeof props.onPublish === 'function') {
        try {
          await props.onPublish(accessManagerPermissions);
        } catch (e) {
          showToastNow({
            type: ToastType.ERROR,
            content: I18n.t('shared.components.asset_action_bar.publication_action.unknown_error')
          });
          airbrake.notify({ error: e, context: { component: 'PublicationAction' } });
        } finally {
          closeAccessManager();
        }
      } else {
        // if we're publishing and the grid view has unsaved changes... save them
        if (shouldUpdateViewOnPublish()) {
          await saveViewChanges();
        }

        try {
          const isStoryView = isStory(view);
          const resp = await fetch(publishUrl(view, true), {
            ...fetchOptions,
            headers: defaultHeaders,
            ...(isStoryView && { body: JSON.stringify({ digest: window.STORY_DATA.digest }) }),
            method: 'POST'
          });
          const status = resp.status;
          const publishedView = await resp.json().then(async (body) => {
            let pubView;
            if (status === 202) {
              pubView = await pollForView(view.id, body.ticket);
            } else {
              pubView = body;
            }
            return pubView;
          });

          // Hit the permissions API after the asset has been published.
          // This ensures that when assets are published from private/site scope to public
          // the permissions endpoint knows whether it needs to go through the approvals workflow
          // and know which Eurybates events to emit
          if (accessManagerPermissions) {
            await savePermissions(view.id, accessManagerPermissions);
          }

          // Storyteller returns an internal id in `id`, which we don't want.
          // Fortunately, it also returns `uid` which is what we want.
          const id = publishedView.uid || publishedView.id;

          if (!id) {
            const errorMessage = publishedView.message || 'Error publishing asset';
            throw new Error(errorMessage);
          }

          // accessManagerPermissions is passed in by access manager (ie on first publish or access updates)
          // view permissions should be used if there are no accessManagerPermissions (which means the permissions scope is not changing)
          const permissions = accessManagerPermissions || view?.permissions;
          // redirect to the published asset, showing a toast about publish success
          const targetAudience = mapPermissionScopeToTargetAudience(permissions?.scope);
          const toastText =
            targetAudience && withGuidanceV2(approvalsGuidance).willEnterApprovalQueue(targetAudience)
              ? 'shared.components.asset_action_bar.publication_action.submitted_asset_for_approval'
              : `shared.site_chrome.access_manager.${mode}.success_toast`;
          showToastOnPageReload({
            type: ToastType.SUCCESS,
            content: I18n.t(toastText)
          });
          window.location.href = optionallyLocalizeUrls(`/d/${id}`); // eslint-disable-line require-atomic-updates
        } catch (e) {
          const errorMessage = e.message || 'Error publishing asset.';
          throw new Error(errorMessage);
        }
      }
    } else {
      if (accessManagerPermissions) {
        await savePermissions(view.id, accessManagerPermissions);
      }
      // permissions have been saved; hide the access manager and show a toast
      // since (generally) things in here are changing various parts of the asset that will
      // most likely not be reflected, we reload the whole page
      buildToastMessage(mode, accessManagerPermissions);
      window.location.reload();
    }
  };

  const buildToastMessage = (mode: ACCESS_MANAGER_MODES, accessManagerPermissions?: ViewPermissions) => {
    const { approvalsGuidance, view } = props;
    // accessManagerPermissions is passed in by access manager (ie on first publish or access updates)
    // view permissions should be used if there are no accessManagerPermissions (which means the permissions scope is not changing)
    const permissions = accessManagerPermissions || view.permissions;
    const targetAudience = mapPermissionScopeToTargetAudience(permissions?.scope);

    // re: the const below
    // - we should never be showing the "submitted asset" toast except on audience changes.
    //    - Publication can show a "submit" toast, but publication doesn't flow through here.
    //    - Also, we explicitly check accessManagerPermissions.scope to make sure the user has selected an audience value,
    //    otherwise we can get a false positive from willEnterApprovalQueue
    // - An asset can downgrade to private, thus we should only check if it will enter the approvals queue
    //    if it isn't changing to private. Otherwise, willEnterApprovalQueue could return a false positive
    const willEnterQueue =
      accessManagerPermissions &&
      accessManagerPermissions?.scope !== AudienceScope.Private &&
      mode === ACCESS_MANAGER_MODES.CHANGE_AUDIENCE &&
      targetAudience &&
      withGuidanceV2(approvalsGuidance).willEnterApprovalQueue(targetAudience);

    // redirect to the published asset, showing a toast about publish success
    const toastText = willEnterQueue
      ? 'shared.components.asset_action_bar.publication_action.submitted_asset_for_approval'
      : `shared.site_chrome.access_manager.${mode}.success_toast`;

    showToastOnPageReload({
      type: ToastType.SUCCESS,
      content: I18n.t(toastText)
    });
  };

  /**
   * TODO: turn this into a separate component
   */
  const renderPrimaryActionButton = () => {
    const { approvalsGuidance, view, editDraftButton, renderPrimaryButton, primaryAction } = props;

    // these two are only used for the Publish and SubmitForReview buttons
    const disableUpdateDueToMetadata = validityStatus !== 'success' || !metadataIsValid;
    const disabledReason = disableUpdateDueToMetadata ? (
      <FixMetadataMessage
        fetchValidityStatus={validityStatus}
        fixMetadataLink={`/d/${view.id}/edit_metadata`}
      />
    ) : undefined;

    const storyEditButton = () => view.viewType === 'story' && <StoryEditButton key="edit" />;
    switch (primaryAction) {
      case PrimaryAction.NONE:
        return null;
      case PrimaryAction.SHOW_DSMP_EDITS:
        const openRevisions = get(window, 'initialState.view.openRevisions', []);
        const revisionSeq = openRevisions
          .map((rev: { revision_seq: string }) => rev.revision_seq)
          .sort()
          .reverse()[0];
        const link = `/d/${view.id}/revisions/${revisionSeq}`;
        return (
          <a href={link} id="aab-view-edit-btn" className="btn btn-primary btn-dark">
            {I18n.t('shared.components.asset_action_bar.publication_action.view_edits')}
          </a>
        );
      case PrimaryAction.SHOW_PRIMER:
        return (
          <a href={`/d/${view.id}`} className="btn btn-primary btn-dark">
            {I18n.t('shared.components.asset_action_bar.publication_action.view_published')}
          </a>
        );
      case PrimaryAction.DELEGATE_BUTTON_TO_APP:
        return renderPrimaryButton
          ? renderPrimaryButton({
              approvalsGuidance,
              view,
              previouslyPublished: hasBeenPublished(view),
              onClick: () => publishButtonOnClick(),
              publishing
            })
          : null;
      case PrimaryAction.SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT:
        const disabled = isStory(view) && !window.DRAFT_HAS_CHANGES;
        const submitToApprovalButton = (
          <SubmitForApprovalButton
            approvalsGuidance={approvalsGuidance}
            disabled={disabled || disableUpdateDueToMetadata}
            disabledReason={disabledReason}
            key="approval"
            view={view}
          />
        );
        return [editDraftButton, submitToApprovalButton];
      case PrimaryAction.GENERIC_EDIT_BUTTON:
        return <EditButton currentView={view} approvalsGuidance={approvalsGuidance} />;
      case PrimaryAction.PUBLISH_OR_EDIT_DRAFT:
        const publishButton = (
          <PublishButton
            key="publish"
            previouslyPublished={hasBeenPublished(view)}
            onClick={() => publishButtonOnClick()}
            disabled={disableUpdateDueToMetadata}
            disabledReason={disabledReason}
          />
        );
        return [storyEditButton(), editDraftButton, publishButton];
      case PrimaryAction.EDIT_DRAFT:
        return [editDraftButton, storyEditButton()];
      default:
        throw new Error(`Unsupported primary action: ${primaryAction}`);
    }
  };

  const { approvalsGuidance, view } = props;

  return (
    <div className="publication-action">
      {renderPrimaryActionButton()}

      <PublicationMoreActionButton {...props} updateAccessManagerMode={setAccessManagerMode} />

      {accessManagerMode !== null && (
        <AccessManager
          mode={accessManagerMode}
          approvalsGuidance={approvalsGuidance}
          view={view}
          onConfirm={saveChanges}
          onDismiss={closeAccessManager}
        />
      )}
    </div>
  );
}
