import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';

import { MODES } from 'common/components/AccessManager/Constants';

import * as uiActions from 'common/components/AccessManager/actions/UiActions';
import * as changeOwnerActions from 'common/components/AccessManager/actions/ChangeOwnerActions';

import {
  getConfirmButtonDisabled,
  getViewerChangeRequiresConfirmation
} from 'common/components/AccessManager/Selectors';
import {
  getSelectedOwner,
  getShowApprovalMessage,
  getUiMode,
  getFooterConfig
} from 'common/components/AccessManager/sagas/Selectors';

import './confirm-button.scss';

const classNameScope = 'common--components--AccessManager--components--Footer';

/**
 * Returns the text to render inside the button
 * @param mode Current access manager mode
 * @param showApprovalMessage Whether or not to show the approvals message
 */
const getText = (mode: MODES, showApprovalMessage: boolean) => {
  if (mode) {
    // if the approval message is being shown, and we're in a mode that
    // cares about scope, show a message about approval instead
    if (showApprovalMessage && (mode === MODES.PUBLISH || mode === MODES.CHANGE_AUDIENCE)) {
      return I18n.t('shared.site_chrome.access_manager.submit_for_approval');
    }

    switch (mode) {
      case MODES.PUBLISH:
        return I18n.t('shared.site_chrome.access_manager.publish_button');
      case MODES.CHANGE_OWNER:
        return I18n.t('shared.site_chrome.access_manager.transfer');
      case MODES.CHANGE_AUDIENCE:
      case MODES.MANAGE_COLLABORATORS:
      case MODES.MANAGE_PLUGIN:
        return I18n.t('shared.site_chrome.access_manager.save');
      case MODES.CONFIRM_DESTRUCTIVE_AUDIENCE_CHANGE:
        return I18n.t(
          'shared.site_chrome.access_manager.confirm_destructive_audience_change.confirm_button_text'
        );
      default:
        console.error(`Unknown mode in FooterConfirmButton: ${mode}. Using default "Save" text.`);
    }
  }

  return I18n.t('shared.site_chrome.access_manager.save');
};

/**
 * Confirm button that appears in the footer.
 *
 * This component is "smart" in that its text gets changed based on the mode of the
 * access manager, and whether or not it has been detected that the asset will go through the
 * approvals workflow once the button is clicked.
 */
const FooterConfirmButton: FunctionComponent = () => {
  const dispatch = useDispatch();
  const mode = useSelector(getUiMode);
  const showApprovalMessage = useSelector(getShowApprovalMessage);
  const footerConfig = useSelector(getFooterConfig);
  const selectedOwner = useSelector(getSelectedOwner);
  const confirmButtonDisabled = useSelector(getConfirmButtonDisabled);
  const needsConfirm = useSelector(getViewerChangeRequiresConfirmation);

  const { confirmButtonBusy } = footerConfig;

  const onClick = () => {
    switch (mode) {
      case MODES.CHANGE_OWNER:
        // confirm the selected owner before saving
        if (selectedOwner) {
          dispatch(changeOwnerActions.confirmSelectedOwner(selectedOwner));
        }
        break;
      case MODES.CHANGE_AUDIENCE:
        if (needsConfirm) {
          dispatch(uiActions.switchModes(MODES.CONFIRM_DESTRUCTIVE_AUDIENCE_CHANGE));

          // we don't want to trigger the "save button clicked" action so we return  here
          return;
        }
        break;
      default:
        break;
    }

    dispatch(uiActions.saveButtonClicked());
  };

  return (
    <Button
      onClick={onClick}
      disabled={confirmButtonDisabled || confirmButtonBusy}
      busy={confirmButtonBusy}
      variant={VARIANTS.PRIMARY}
      className={`${classNameScope}--confirm-button`}
    >
      {getText(mode, showApprovalMessage)}
    </Button>
  );
};

export default FooterConfirmButton;
